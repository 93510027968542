import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/SettingEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";

import {useToast} from "vue-toastification";

export interface mealPreparedData {
    meal_prepares: object
}

export interface locationData {
    location_list: object
}

export interface dispatchlocationData {
    meal_dispatch_location_list: object
}

@Module
export default class MealRateModule extends VuexModule implements mealPreparedData {

    meal_prepares = {}
    location_list ={}
    meal_dispatch_location_list ={}

   
    get getMealPrepareList(): object {
        return this.meal_prepares;
    }

    get getLocationList(): object {
        return this.location_list;
    }

    get getMealDispatchLocationList(): object {
        return this.meal_dispatch_location_list;
    }

    @Mutation // fetched meal prepare list data are saved in this function
    [Mutations.SET_MEAL_PREPARE_LIST](payload) {
        this.meal_prepares = payload
    }

    @Mutation // fetched location list data are saved in this function
    [Mutations.LOCATION_LIST](payload) {
        this.location_list = payload
    }

    @Mutation // fetched location list data are saved in this function
    [Mutations.SET_MEAL_PREPARE_DISPATCH_LOCATIONS_LIST](payload) {
        this.meal_dispatch_location_list = payload
    }



    @Action // fetchMealPrepareList
    [Actions.FETCH_MEAL_PREPARE_LIST]() {
        return ApiService.get("/meal-prepared-index").then(({data}) => {
            this.context.commit(Mutations.SET_MEAL_PREPARE_LIST, data.mealPreparedData);
            this.context.commit(Mutations.LOCATION_LIST, data.locations);
        }).catch(({response}) => {
            toast('error', response.data)
        });
    }

   

    @Action // storeMealPrepare
    [Actions.STORE_MEAL_PREPARE](payload) {
        return ApiService.post("/meal-prepared-store", payload).then(({data}) => {
            toast('success', data)
        }).catch(({response}) => {
            toast('error', response.data)
        });
    }

    @Action // MealPrepareFinish
    [Actions.MEAL_PREPARE_FINISH](payload) {
        return ApiService.post("/meal-prepared-finished",payload)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action //dispatch
    [Actions.MEAL_PREPARE_DISPATCH](payload) {
        return ApiService.post("/meal-prepared-dispatch",payload)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }


    @Action //meal prepare dispatch locations list
    [Actions.FETCH_MEAL_PREPARE_DISPATCH_LOCATIONS](payload) {
        return ApiService.get("/meal-prepared-dispatch-locations/" + payload)
            .then(({data}) => {
                this.context.commit(Mutations.SET_MEAL_PREPARE_DISPATCH_LOCATIONS_LIST, data.mealViewStatus);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action //meal prepare dispatch locations list
        [Actions.MEAL_PREPARE_RECEIVED](payload) {
            return ApiService.post("/meal-prepared-received/",payload)
                .then(({data}) => {
                    toast('success', data)
                })
                .catch(({response}) => {
                    toast('error', response.data)
                });
        }



}


// common function
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 5000,
        })
    } else {
        useToast().success(data.message, {
            timeout: 5000,
        })
    }
}
