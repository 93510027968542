import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/ReportEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";

import {useToast} from "vue-toastification";
import moment from "moment/moment";

export interface WeeklyMealReportData {
    weekly_meal_report: object,
}

@Module
export default class WeeklyMealReportModule extends VuexModule implements WeeklyMealReportData {

    weekly_meal_report = {};

    /**
     * shows stock history data
     * @returns object
     */
    get getWeeklyMealReport(): object {
        return this.weekly_meal_report;
    }

    @Mutation // setWeeklyReport
    [Mutations.SET_WEEKLY_MEAL_REPORT](payload) {
        this.weekly_meal_report = payload
    }

    @Action // fetchWeeklyMealReport
    [Actions.FETCH_WEEKLY_MEAL_REPORT](payload) {
        return ApiService.post("/report/weekly-meal-report", payload).then(({data}) => {
            this.context.commit(Mutations.SET_WEEKLY_MEAL_REPORT, data.weekly_status_report);
        }).catch(({response}) => {
            console.log('WeeklyMealReportModule ', response)
            toast('error', response.data)
        });
    }

    @Action // exportWeeklyMealReport
    [Actions.EXPORT_WEEKLY_MEAL_REPORT](payload) {
        const from = moment(payload.date[0]).format('YYYY-MM-DD')
        const to = moment(payload.date[1]).format('YYYY-MM-DD')
        // console.log(from,to);
        return ApiService.pdfDownloadPost("/report/weekly-meal-report/download", payload).then(({data}) => {
            const url = window.URL.createObjectURL(new Blob([data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', from + '_' + to + '_weekly_meal_report.pdf')
            document.body.appendChild(link)
            link.click()
        }).catch(({response}) => {
            console.error('WeeklyMealReportModule', response)
            toast('error', response.data)
        });
    }

    
    @Action // exportExcelWeeklyMealReport
    [Actions.EXCEL_EXPORT_WEEKLY_MEAL_REPORT](payload) {
        const from = moment(payload.date[0]).format('YYYY-MM-DD')
        const to = moment(payload.date[1]).format('YYYY-MM-DD')
        return ApiService.pdfDownloadPost("/report/weekly-meal-report/excel-download", payload).then(({data}) => {
            const url = window.URL.createObjectURL(new Blob([data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', from + '_' + to + '_weekly_meal_report.xlsx')
            document.body.appendChild(link)
            link.click()
        }).catch(({response}) => {
            console.error('WeeklyMealReportModule', response)
            toast('error', response.data)
        });
    }

}

// common function
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 5000,
        })
    } else {
        useToast().success(data.message, {
            timeout: 5000,
        })
    }

}
