enum Actions {

    FETCH_KITCHEN_STATION_LIST = "fetchKitchenStationList",
    FETCH_MONTHLY_KITCHEN_COSTING_LIST = "fetchMonthlyKitchenCostingList",
    FETCH_MONTHLY_KITCHEN_COSTING_DOWNLOAD_PDF = "fetchMonthlyKitchenCostingDownloadPdf",
    FETCH_MONTHLY_KITCHEN_COSTING_DOWNLOAD_EXCEL = "fetchMonthlyKitchenCostingDownloadExcel",
    FETCH_LOCATION_LIST = "fetchLocationList",
    FETCH_MONTHLY_FOOD_DELIVERY_STATUS_LIST = "fetchMonthlyFoodDeliveryStatusList",
    FETCH_MONTHLY_FOOD_DELIVERY_STATUS_DOWNLOAD_PDF = "fetchMonthlyFoodDeliveryStatusDownloadPdf",
    FETCH_MONTHLY_FOOD_DELIVERY_DOWNLOAD_EXCEL = "fetchMonthlyFoodDeliveryStatusDownloadExcel",

    //Inbound Report History
    FETCH_INBOUND_HISTORY = "fetchInboundHistory",
    EXPORT_INBOUND_HISTORY = "exportInboundHistory",

    // Inventory Report History
    FETCH_INVENTORY_HISTORY = "fetchInventoryHistory",
    EXPORT_INVENTORY_HISTORY = "exportInventoryHistory",

    // Inventory Consumption Report
    FETCH_INBOUND_CONSUMPTION_REPORT = "fetchInboundConsumptionReport",
    EXPORT_INBOUND_CONSUMPTION_REPORT = "exportInboundConsumptionReport",

    // Daily guest meal report
    FETCH_DAILY_GUEST_MEAL_REPORT = 'fetchDailyGuestMealReport',
    EXPORT_DAILY_GUEST_MEAL_REPORT = 'exportDailyGuestMealReport',

    // Weekly report
    FETCH_WEEKLY_MEAL_REPORT = 'fetchWeeklyMealReport',
    EXPORT_WEEKLY_MEAL_REPORT = 'exportWeeklyMealReport',
    EXCEL_EXPORT_WEEKLY_MEAL_REPORT = 'excelExportWeeklyMealReport',



    // Campaign meal report
    FETCH_CAMPAIGN_MEAL_REPORT = 'fetchCampaignMealReport',
    EXPORT_CAMPAIGN_MEAL_REPORT = 'exportCampaignMealReport',

    // Subscriber and unsubscribe list report
    FETCH_SUBSCRIBER_AND_UNSUBSCRIBE_REPORT = 'fetchSubscriberAndUnsubscribeReport',
    EXPORT_SUBSCRIBER_AND_UNSUBSCRIBE_REPORT = 'exportSubscriberAndUnsubscribeReport',

    // Monthly Purchase request list report
    FETCH_MONTHLY_PURCHASE_REQUEST_REPORT = 'fetchMonthlyPurchaseRequestReport',
    EXPORT_MONTHLY_PURCHASE_REQUEST_REPORT = 'exportMonthlyPurchaseRequestReport',
}

enum Mutations {

    SET_ERROR = "setError",
    SET_SUCCESS = "setSuccess",

    SET_MONTHLY_KITCHEN_COSTING_LIST = "setMonthlyKitchenCostingList",
    SET_KITCHEN_STATION_LIST = "setKitchenStationList",
    SET_MONTHLY_FOOD_DELIVERY_STATUS_LIST = "setMonthlyFoodDeliveryList",
    SET_LOCATION_LIST = "setLocationList",

    // Inventory Report History
    SET_STOCK_HISTORY = 'setStockHistory',

    //Inbound Report History
    SET_INBOUND_HISTORY = 'setInboundHistory',

    // Inventory Consumption Report
    SET_INBOUND_CONSUMPTION_REPORT = 'setInboundConsumptionReport',

    // Daily guest meal report
    SET_DAILY_GUEST_MEAL_REPORT = 'setDailyGuestMealReport',

    // Daily weekly meal report
    SET_WEEKLY_MEAL_REPORT = 'setWeeklyMealReport',

    // Campaign meal report
    SET_CAMPAIGN_MEAL_REPORT = 'setCampaignMealReport',

    // Subscriber and unsubscribe list
    SET_SUBSCRIBER_AND_UNSUBSCRIBE_REPORT = 'setSubscriberAndUnsubscribeReport',

    // Monthly purchase request list
    SET_MONTHLY_PURCHASE_REQUEST_REPORT = 'setMonthlyPurchaseRequestReport',


}

export {Actions, Mutations};
