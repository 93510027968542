import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/DashboardEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";

import {useToast} from "vue-toastification";
import router from "@/router";
import {array} from "yup"; // importing toast library

export interface DashboardData {
    activeBreakfastSubscribers: null | number;
    activeLunchSubscribers: null | number;
    activeDriver: null | number;
    activeSupportStaff: null | number;

    totalSubscriberBreakfastMealConsumptions: null | number;
    totalSubscriberLunchMealConsumptions: null | number;
    totalSubscriberBreakfastMeals: null | number;
    totalSubscriberLunchMeals: null | number;

    religionCount: object,
    mealCountPerLocation: object,

    dailyLunchFoodItem: object,
    dailyBreakfastFoodItem: object,
    foodItemsList: object,
    feastList: object,
    upcoming_campaign_meals: object,
    monthly_subscriber_and_unsubscribe_chart: object,
    monthly_purchase_orders_chart: object,
}

@Module
export default class DashboardModule extends VuexModule implements DashboardData {

    activeBreakfastSubscribers = null;
    activeLunchSubscribers = null;
    activeDriver = null;
    activeSupportStaff = null;

    totalSubscriberBreakfastMealConsumptions = null;
    totalSubscriberLunchMealConsumptions = null;
    totalSubscriberBreakfastMeals = null;
    totalSubscriberLunchMeals = null;

    religionCount = {};
    mealCountPerLocation = {};

    dailyLunchFoodItem = {};
    dailyBreakfastFoodItem = {};
    foodItemsList = {};
    feastList = {};
    upcoming_campaign_meals = {};
    monthly_subscriber_and_unsubscribe_chart = {};
    monthly_purchase_orders_chart = {};

    /**
     * shows active user data
     * @returns object
     */
    get getActiveUser() {
        return {
            'driver': this.activeDriver,
            'breakfast_subscribers': this.activeBreakfastSubscribers,
            'lunch_subscribers': this.activeLunchSubscribers,
            'supportStaff': this.activeSupportStaff,
        };
    }

    /**
     * shows daily meal status
     * @returns object
     */
    get getDailySubscriberMealStatus() {
        return {
            'breakfastConsume': this.totalSubscriberBreakfastMealConsumptions ? this.totalSubscriberBreakfastMealConsumptions : 0,
            'lunchConsume': this.totalSubscriberLunchMealConsumptions ? this.totalSubscriberLunchMealConsumptions : 0,
            'totalBreakfastMeal': this.totalSubscriberBreakfastMeals ? this.totalSubscriberBreakfastMeals : 0,
            'totalLunchMeal': this.totalSubscriberLunchMeals ? this.totalSubscriberLunchMeals : 0,
         }
    }

    /**
     * shows daily food items data
     * @returns object
     */
    get getDailyLunchFoodItemList(): object {
        return this.dailyLunchFoodItem;
    }
    /**
     * shows daily food items data
     * @returns object
     */
    get getDailyBreakfastFoodItemList(): object {
        return this.dailyBreakfastFoodItem;
    }

    /**
     * shows feast list
     * @returns object
     */
    get getFeastList(): object {
        return this.feastList;
    }

    /**
     * shows food items list
     * @returns object
     */
    get getFoodItemsList(): object {
        return this.foodItemsList;
    }

    /**
     * shows religion count
     * @returns object
     */
    get getReligionCount(): object {
        return this.religionCount;
    }

    /**
     * shows meal count per religion
     * @returns object
     */
    get getMealCountPerLocation(): object {
        return this.mealCountPerLocation;
    }

    /**
     * shows meal count per religion
     * @returns object
     */
    get getUpcomingCampaignMeals(): object {
        return this.upcoming_campaign_meals;
    }

    /**
     * shows meal count per religion
     * @returns object
     */
    get getMonthlySubscriberAndUnsubscribeChart(): object {
        return this.monthly_subscriber_and_unsubscribe_chart;
    }

    /**
     * shows meal count per religion
     * @returns object
     */
    get getMonthlyPurchaseRequestChart(): object {
        return this.monthly_purchase_orders_chart;
    }


    @Mutation // setDailyFoodItemList
    [Mutations.SET_DASHBOARD_DATA](payload) {

        // active user data
        this.activeBreakfastSubscribers = payload.active_breakfast_subscribers;
        this.activeLunchSubscribers = payload.active_lunch_subscribers;
        this.activeDriver = payload.active_driver;
        this.activeSupportStaff = payload.active_support_staff;

        // daily meal status
        this.totalSubscriberBreakfastMealConsumptions = payload.today_delivery_and_consumption[0].total_subscriber_breakfast_meal_consumptions;
        this.totalSubscriberLunchMealConsumptions = payload.today_delivery_and_consumption[0].total_subscriber_lunch_meal_consumptions;
        this.totalSubscriberBreakfastMeals = payload.today_delivery_and_consumption[0].total_subscriber_breakfast_meals;
        this.totalSubscriberLunchMeals = payload.today_delivery_and_consumption[0].total_subscriber_lunch_meals;
    }

    @Mutation // setDailyLunchFoodItemList
    [Mutations.SET_DAILY_LUNCH_FOOD_ITEM_LIST](payload) {
        this.dailyLunchFoodItem = payload
    }
    @Mutation // setDailyBreakfastFoodItemList
    [Mutations.SET_DAILY_BREAKFAST_FOOD_ITEM_LIST](payload) {
        this.dailyBreakfastFoodItem = payload
    }

    @Mutation // SetFeastList
    [Mutations.SET_FEAST_LIST](payload) {
        this.feastList = payload
    }

    @Mutation // setFoodItemList
    [Mutations.SET_FOOD_ITEM_LIST](payload) {
        this.foodItemsList = payload
    }

    @Mutation // setFoodItemList
    [Mutations.SET_RELIGION_COUNT](payload) {
        this.religionCount = payload
    }

    @Mutation // setFoodItemList
    [Mutations.SET_MEAL_COUNT_PER_LOCATION](payload) {
        this.mealCountPerLocation = payload
    }

    @Mutation // setUpcomingCampaignMeal
    [Mutations.SET_UPCOMING_CAMPAIGN_MEAL_FOR_DASHBOARD](payload) {
        this.upcoming_campaign_meals = payload
    }
    @Mutation // setUpcomingCampaignMeal
    [Mutations.SET_MONTHLY_SUBSCRIBER_AND_UNSUBSCRIBE_CHART](payload) {
        this.monthly_subscriber_and_unsubscribe_chart = payload
    }

    @Mutation // setPurchaseRequestChart
    [Mutations.SET_MONTHLY_PURCHASE_REQUEST_CHART](payload) {
        this.monthly_purchase_orders_chart = payload
    }

    @Action // fetchDashboardData
    [Actions.FETCH_DASHBOARD_DATA](payload) {
        return ApiService.get("/dashboard", payload)
            .then(({data}) => {
                this.context.commit(Mutations.SET_DASHBOARD_DATA, data);
                this.context.commit(Mutations.SET_DAILY_BREAKFAST_FOOD_ITEM_LIST, data.daily_breakfast_food_item);
                this.context.commit(Mutations.SET_DAILY_LUNCH_FOOD_ITEM_LIST, data.daily_lunch_food_item);
                this.context.commit(Mutations.SET_FOOD_ITEM_LIST, data.food_item_lists);
                this.context.commit(Mutations.SET_FEAST_LIST, data.feast_list);
                this.context.commit(Mutations.SET_RELIGION_COUNT, data.religion[0]);
                this.context.commit(Mutations.SET_MEAL_COUNT_PER_LOCATION, data.meal_count_per_location);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetchUpcomingCampaignMeal
    [Actions.FETCH_UPCOMING_CAMPAIGN_MEAL_FOR_DASHBOARD](payload) {
        return ApiService.get("/upcoming-campaign-meal/dashboard", payload)
            .then(({data}) => {
                this.context.commit(Mutations.SET_UPCOMING_CAMPAIGN_MEAL_FOR_DASHBOARD, data.upcoming_campaign_meals);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetchMonthlySubscriberAndUnsubscribeChart
    [Actions.FETCH_MONTHLY_SUBSCRIBER_AND_UNSUBSCRIBE_CHART](payload) {
        return ApiService.post("/monthly-subscriber-and-unsubscribe/chart", payload)
            .then(({data}) => {
                this.context.commit(Mutations.SET_MONTHLY_SUBSCRIBER_AND_UNSUBSCRIBE_CHART, data.subscribers);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetchMonthlyPurchaseRequestChart
    [Actions.FETCH_MONTHLY_PURCHASE_REQUEST_CHART](payload) {
        return ApiService.post("/monthly-purchase-request/chart", payload)
            .then(({data}) => {
                this.context.commit(Mutations.SET_MONTHLY_PURCHASE_REQUEST_CHART, data.monthly_purchase_orders_chart);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }
}

// common functions
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 2000,
        })
    } else if (type == 'success') {
        useToast().success(data.message, {
            timeout: 2000,
        })
    }
}

