enum Actions {

    FETCH_SUBSCRIPTION_LIST = "fetchSubscriptionList",
    FETCH_LATEST_SUBSCRIPTION = "fetchLatestSubscription",

    FETCH_TODAY_LUNCH_SUBSCRIPTION_LIST = "fetchTodayLunchSubscriptionList",
    FETCH_TODAY_BREAKFAST_SUBSCRIPTION_LIST = "fetchTodayBreakfastSubscriptionList",

    FETCH_ADMIN_TODAY_LUNCH_SUBSCRIPTION_LIST = "fetchAdminTodayLunchSubscriptionList",
    FETCH_ADMIN_TODAY_BREAKFAST_SUBSCRIPTION_LIST = "fetchAdminTodayBreakfastSubscriptionList",

    FETCH_TODAY_BREAKFAST_GUEST_MEAL_SUBSCRIPTION_LIST = "fetchTodayBreakfastGuestMealSubscriptionList",
    FETCH_TODAY_LUNCH_GUEST_MEAL_SUBSCRIPTION_LIST = "fetchTodayLunchGuestMealSubscriptionList",

    FETCH_ADMIN_TODAY_BREAKFAST_GUEST_MEAL_SUBSCRIPTION_LIST = "fetchAdminTodayBreakfastGuestMealSubscriptionList",
    FETCH_ADMIN_TODAY_LUNCH_GUEST_MEAL_SUBSCRIPTION_LIST = "fetchAdminTodayLunchGuestMealSubscriptionList",
    FETCH_TODAY_DELIVERY_SUBSCRIPTION_LIST = "fetchTodayDeliverySubscriptionList",

    STORE_TODAY_LUNCH_SUBSCRIPTION = "storeTodayLunchSubscription",
    STORE_TODAY_BREAKFAST_SUBSCRIPTION = "storeTodayBreakfastSubscription",

    STORE_TODAY_ALL_LUNCH_SUBSCRIBER_SUBSCRIPTION = "storeTodayAllLunchSubscriberSubscription",
    STORE_TODAY_ALL_BREAKFAST_SUBSCRIBER_SUBSCRIPTION = "storeTodayAllBreakfastSubscriberSubscription",

    STORE_TODAY_BREAKFAST_GUEST_MEAL_SUBSCRIPTION = "storeTodayBreakfastGuestMealSubscription",
    STORE_TODAY_LUNCH_GUEST_MEAL_SUBSCRIPTION = "storeTodayLunchGuestMealSubscription",

    STORE_TODAY_ALL_BREAKFAST_GUEST_MEAL_SUBSCRIPTION = "storeTodayAllBreakfastGuestMealSubscription",
    STORE_TODAY_ALL_LUNCH_GUEST_MEAL_SUBSCRIPTION = "storeTodayAllLunchGuestMealSubscription",

    FETCH_SUBSCRIBER_DAILY_SUBSCRIPTION_LIST = "fetchSubscriberDailySubscriptionList",
    FETCH_SUBSCRIBER_DAILY_SUBSCRIPTION = "fetchSubscriberDailySubscription",

    CANCEL_SUBSCRIPTION = "cancelSubscription",
    START_SUBSCRIPTION = "startSubscription",
    DELETE_SUBSCRIPTION = "deleteSubscription",

    ADMIN_TODAY_LUNCH_SUBSCRIPTION_EXCEL_DOWNLOAD = "adminTodayLunchSubscriptionExcelDownload",
    ADMIN_TODAY_BREAKFAST_SUBSCRIPTION_EXCEL_DOWNLOAD = "adminTodayBreakfastSubscriptionExcelDownload",

    KITCHEN_COUNTER_TODAY_LUNCH_SUBSCRIPTION_EXCEL_DOWNLOAD = "kitchenCounterTodayLunchSubscriptionExcelDownload",
    KITCHEN_COUNTER_TODAY_BREAKFAST_SUBSCRIPTION_EXCEL_DOWNLOAD = "kitchenCounterTodayBreakfastSubscriptionExcelDownload",
}

enum Mutations {

    SET_ERROR = "setError",
    SET_SUCCESS = "setSuccess",

    SET_SUBSCRIPTION_LIST = "setSubscriptionList",
    SET_SUBSCRIPTION_BUTTON_VISIBILITY = "setSubscriptionButtonVisibility",
    SET_LATEST_BREAKFAST_SUBSCRIPTION = "setLatestBreakfastSubscription",
    SET_LATEST_LUNCH_SUBSCRIPTION = "setLatestLunchSubscription",

    SET_TODAY_LUNCH_SUBSCRIPTION_LIST = "setTodayLunchSubscription",
    SET_TODAY_BREAKFAST_SUBSCRIPTION_LIST = "setTodayBreakfastSubscription",

    SET_TODAY_LUNCH_SUBSCRIPTION_IDS_LIST = "setTodayLunchSubscriptionIds",
    SET_TODAY_BREAKFAST_SUBSCRIPTION_IDS_LIST = "setTodayBreakfastSubscriptionIds",

    SET_TODAY_BREAKFAST_GUEST_MEAL_SUBSCRIPTION_LIST = "setTodayBreakfastGuestMealSubscription",
    SET_TODAY_BREAKFAST_GUEST_MEAL_SUBSCRIPTION_IDS_LIST = "setTodayBreakfastGuestMealSubscriptionIds",

    SET_TODAY_LUNCH_GUEST_MEAL_SUBSCRIPTION_LIST = "setTodayLunchGuestMealSubscription",
    SET_TODAY_LUNCH_GUEST_MEAL_SUBSCRIPTION_IDS_LIST = "setTodayLunchGuestMealSubscriptionIds",

    SET_ADMIN_TODAY_GUEST_MEAL_SUBSCRIPTION_LIST = "setAdminTodayGuestMealSubscription",
    SET_ADMIN_TODAY_GUEST_MEAL_SUBSCRIPTION_IDS_LIST = "setAdminTodayGuestMealSubscriptionIds",

    // Start Admin
    SET_ADMIN_TODAY_LUNCH_SUBSCRIPTION_LIST = "setAdminTodayLunchSubscription",
    SET_ADMIN_TODAY_LUNCH_SUBSCRIPTION_IDS_LIST = "setAdminTodayLunchSubscriptionIds",

    SET_ADMIN_TODAY_BREAKFAST_SUBSCRIPTION_LIST = "setAdminTodayBreakfastSubscription",
    SET_ADMIN_TODAY_BREAKFAST_SUBSCRIPTION_IDS_LIST = "setAdminTodayBreakfastSubscriptionIds",
    // End Admin

    SET_TODAY_DELIVERY_SUBSCRIPTION_LIST = "setTodayDeliverySubscription",

    SET_SUBSCRIBER_DAILY_SUBSCRIPTION_LIST = "setSubscriberDailySubscriptionList",
    SET_SUBSCRIBER_DAILY_SUBSCRIPTION = "setSubscriberDailySubscription",
}

export {Actions, Mutations};