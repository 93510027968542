import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/AdminSubscriptionEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import {useToast} from "vue-toastification";

export interface Subscriptions {
    subscriptions: Array<object>
}

export interface TodaySubscriptions {
    today_subscriptions: Array<object>
}

export interface Subscribers {
    subscribers: Array<object>
}

export interface CancelledSubscribers {
    subscribers: Array<object>
}

export interface SubscriberSubscriptions {
    subscriber_subscriptions: Array<object>
}

export interface SubscriberDailySubscriptions {
    subscriber_daily_subscriptions: Array<object>
}
export interface UserDetail {
    user_detail: Array<object>
}

export interface SubscriberAlternativeGuestMeals {
    alternative_guest_meals: Array<object>
}

export interface SubscriberAlternativeLocations {
    alternative_locations: Array<object>
}

export interface Subscription {
    subscription: object
}
export interface Subscriber {
    subscriber: object
}

export interface SubscriptionInfo {
    errors: unknown;
    success: unknown;
    subscriptions: Subscriptions;
    today_subscriptions: TodaySubscriptions;
    subscribers: Subscribers;
    cancelledSubscribers: CancelledSubscribers
    subscriber_subscriptions: SubscriberSubscriptions;
    breakfast_subscription: Subscription;
    lunch_subscription: Subscription;
    subscriber_daily_subscriptions: SubscriberDailySubscriptions;
    user_detail: UserDetail;
    alternative_guest_meals: SubscriberAlternativeGuestMeals;
    alternative_locations: SubscriberAlternativeLocations;
    subscriber: Subscriber;
}

@Module
export default class SubscriptionModule extends VuexModule implements SubscriptionInfo {
    errors = {};
    success = {};
    subscriptions = {} as Subscriptions;
    today_subscriptions = {} as TodaySubscriptions;
    subscribers = {} as Subscribers;
    breakfast_subscribers = {} as Subscribers;
    cancelledSubscribers = {} as CancelledSubscribers;
    subscriber_subscriptions = {} as SubscriberSubscriptions;
    breakfast_subscription = {} as Subscription;
    lunch_subscription = {} as Subscription;
    subscriber_daily_subscriptions = {} as SubscriberDailySubscriptions;
    user_detail = {} as UserDetail;
    alternative_guest_meals = {} as SubscriberAlternativeGuestMeals;
    alternative_locations = {} as SubscriberAlternativeLocations;
    subscriber = {} as Subscriber;

    /**
     * Get authentification success
     * @returns array
     */
    get getAdminSubscriptionSuccess() {
        return this.success;
    }

    /**
     * Get authentification errors
     * @returns array
     */
    get getAdminSubscriptionErrors() {
        return this.errors;
    }

    /**
     * shows user type list
     * @returns object
     */
    get getAdminSubscriptions(): object {
        return this.subscriptions;
    }

    /**
     * shows user type list
     * @returns object
     */
    get getAdminSubscribers(): object {
        return this.subscribers;
    }
    /**
     * shows user type list
     * @returns object
     */
    get getAdminBreakfastSubscribers(): object {
        return this.breakfast_subscribers;
    }

    /**
     * shows user type list
     * @returns object
     */
    get getAdminCancelledSubscribers(): object {
        return this.cancelledSubscribers;
    }

    /**
     * shows user type list
     * @returns object
     */
    get getAdminSubscriberSubscriptions(): object {
        return this.subscriber_subscriptions;
    }

    /**
     * shows user type list
     * @returns object
     */
    get getAdminTodaySubscriptions(): object {
        return this.today_subscriptions;
    }

    /**
     * shows user type list
     * @returns object
     */
    get getAdminSubscriberLatestBreakfastSubscription(): object {
        return this.breakfast_subscription;
    }
    /**
     * shows user type list
     * @returns object
     */
    get getAdminSubscriberLatestLunchSubscription(): object {
        return this.lunch_subscription;
    }

    /**
     * shows user type list
     * @returns object
     */
    get getAdminSubscriberDailySubscriptions(): object {
        return this.subscriber_daily_subscriptions;
    }

     /**
     * shows user detail
     * @returns object
     */
     get userDetail(): object {
        return this.user_detail;
    }

    /**
     * shows user type list
     * @returns object
     */
    get getAdminSubscriberAlternativeGuestMeals(): object {
        return this.alternative_guest_meals;
    }

    /**
     * shows user type list
     * @returns object
     */
    get getAdminSubscriberAlternativeLocations(): object {
        return this.alternative_locations;
    }

    /**
     * shows user type list
     * @returns object
     */
    get getAdminIndividuallySubscriber(): object {
        return this.subscriber;
    }

    @Mutation
    [Mutations.SET_SUCCESS](success) {
        this.success = {...success};
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = {...error};
    }

    @Mutation // fetched subscription list data are saved in this function
    [Mutations.SET_SUBSCRIPTION_LIST](payload) {
        this.subscriptions = payload
    }

    @Mutation // fetched subscription list data are saved in this function
    [Mutations.SET_ADMIN_TODAY_SUBSCRIPTION_LIST](payload) {
        this.today_subscriptions = payload
    }

    @Mutation
    [Mutations.SET_ADMIN_SUBSCRIBER_LIST](payload) {
        this.subscribers = payload
    }
    @Mutation
    [Mutations.SET_ADMIN_BREAKFAST_SUBSCRIBER_LIST](payload) {
        this.breakfast_subscribers = payload
    }

    @Mutation
    [Mutations.SET_ADMIN_CANCELLED_SUBSCRIBER_LIST](payload) {
        this.cancelledSubscribers = payload
    }

    @Mutation
    [Mutations.SET_ADMIN_SUBSCRIBER_SUBSCRIPTION_LIST](payload) {
        this.subscriber_subscriptions = payload
    }

    @Mutation
    [Mutations.SET_ADMIN_SUBSCRIBER_LATEST_BREAKFAST_SUBSCRIPTION](payload) {
        this.breakfast_subscription = payload
    }
    @Mutation
    [Mutations.SET_ADMIN_SUBSCRIBER_LATEST_LUNCH_SUBSCRIPTION](payload) {
        this.lunch_subscription = payload
    }

    @Mutation
    [Mutations.SET_ADMIN_SUBSCRIBER_DAILY_SUBSCRIPTION_LIST](payload) {
        this.subscriber_daily_subscriptions = payload
    }
     @Mutation
    [Mutations.SET_USER_DETAIL](payload) {
        this.user_detail = payload
    }


    @Mutation
    [Mutations.SET_ADMIN_SUBSCRIBER_REQUEST_GUEST_MEAL_LIST](payload) {
        this.alternative_guest_meals = payload
    }

    @Mutation
    [Mutations.SET_ADMIN_SUBSCRIBER_LOCATION_LIST](payload) {
        this.alternative_locations = payload
    }

    @Mutation
    [Mutations.SET_ADMIN_INDIVIDUALLY_SUBSCRIBER](payload) {
        this.subscriber = payload
    }

    @Action // fetch subscriptions
    [Actions.FETCH_SUBSCRIPTION_LIST]() {
        return ApiService.get("/subscriptions").then(({data}) => {
            this.context.commit(Mutations.SET_SUBSCRIPTION_LIST, data.subscriptions);
        }).catch(({response}) => {
            toast('error', response.data)
        });
    }

    @Action // fetch today subscriptions
    [Actions.FETCH_ADMIN_TODAY_SUBSCRIPTION_DELIVERY_LIST](payload) {
        // return ApiService.post("/today-subscriptions", payload)
        return ApiService.post("/admin/today-delivery-subscriptions", payload).then(({data}) => {
            this.context.commit(Mutations.SET_ADMIN_TODAY_SUBSCRIPTION_LIST, data.today_subscriptions);
        }).catch(({response}) => {
            toast('error', response.data)
        });
    }

    @Action // store manually guest meal
    [Actions.STORE_MANUALLY_BREAKFAST_GUEST_MEAL](payload) {
        return ApiService.post("/subscription/manually/breakfast-guest-meal/store", payload).then(({data}) => {
            toast('success', data)
        }).catch(({response}) => {
            toast('error', response.data)
        });
    }
    @Action // store manually guest meal
    [Actions.STORE_MANUALLY_LUNCH_GUEST_MEAL](payload) {
        return ApiService.post("/subscription/manually/lunch-guest-meal/store", payload).then(({data}) => {
            toast('success', data)
        }).catch(({response}) => {
            toast('error', response.data)
        });
    }

    @Action // fetch subscriber list
    [Actions.FETCH_ADMIN_SUBSCRIBER_LIST](payload) {
        return ApiService.post("/admin/subscriber/list",payload).then(({data}) => {
            this.context.commit(Mutations.SET_ADMIN_SUBSCRIBER_LIST, data.subscribers);
        }).catch(({response}) => {
            toast('error', response.data)
        });
    }

    @Action // fetch subscriber list
    [Actions.FETCH_ADMIN_BREAKFAST_SUBSCRIBER_LIST](payload) {
        return ApiService.post("/admin/breakfast-subscriber/list",payload).then(({data}) => {
            this.context.commit(Mutations.SET_ADMIN_BREAKFAST_SUBSCRIBER_LIST, data.breakfast_subscriber_list);
        }).catch(({response}) => {
            toast('error', response.data)
        });
    }

    @Action // fetchCancelledSubscriptionList
    [Actions.FETCH_ADMIN_CANCELLED_SUBSCRIBER_LIST](payload) {
        return ApiService.post("/admin/cancelled-subscriber/list",payload).then(({data}) => {
            this.context.commit(Mutations.SET_ADMIN_CANCELLED_SUBSCRIBER_LIST, data.cancel_subscriptions);
        }).catch(({response}) => {
            toast('error', response.data)
        });
    }

    @Action // fetch subscriber list
    [Actions.FETCH_ADMIN_SUBSCRIBER_SUBSCRIPTION_LIST](payload) {
        return ApiService.get("/admin/subscriber/subscription/list/" + payload).then(({data}) => {
            this.context.commit(Mutations.SET_ADMIN_SUBSCRIBER_SUBSCRIPTION_LIST, data.subscriber_subscriptions);
        }).catch(({response}) => {
            toast('error', response.data)
        });
    }

    @Action // fetch subscriber list
    [Actions.FETCH_ADMIN_SUBSCRIBER_LATEST_SUBSCRIPTION](payload) {
        return ApiService.get("/admin/subscriber/latest/subscription/" + payload).then(({data}) => {
            this.context.commit(Mutations.SET_ADMIN_SUBSCRIBER_LATEST_LUNCH_SUBSCRIPTION, data.lunch_subscription);
            this.context.commit(Mutations.SET_ADMIN_SUBSCRIBER_LATEST_BREAKFAST_SUBSCRIPTION, data.breakfast_subscription);
        }).catch(({response}) => {
            toast('error', response.data)
        });
    }

    @Action // start subscription
    [Actions.ADMIN_SUBSCRIBER_START_SUBSCRIPTION](payload) {
        return ApiService.post("/admin/subscriber/start-subscription", payload).then(({data}) => {
            toast('success', data)
        }).catch(({response}) => {
            toast('error', response.data)
        });
    }

    @Action // cancel subscription
    [Actions.ADMIN_SUBSCRIBER_CANCEL_SUBSCRIPTION](payload) {
        return ApiService.post("/admin/subscriber/cancel-subscription", payload).then(({data}) => {
            toast('success', data)
        }).catch(({response}) => {
            toast('error', response.data)
        });
    }

    @Action // fetch subscriber daily subscriptions
    [Actions.FETCH_ADMIN_SUBSCRIBER_DAILY_SUBSCRIPTION_LIST](payload) {
        return ApiService.get("/admin/subscriber/daily-subscription/list/" + payload)
            .then(({data}) => {
                this.context.commit(Mutations.SET_ADMIN_SUBSCRIBER_DAILY_SUBSCRIPTION_LIST, data.subscriber_daily_subscriptions);
                this.context.commit(Mutations.SET_USER_DETAIL, data.user_detail);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetch subscriber guest meal request
    [Actions.FETCH_ADMIN_SUBSCRIBER_REQUEST_GUEST_MEAL_LIST](payload) {
        return ApiService.get("/admin/subscriber/alternative-guest-meal/list/" + payload)
            .then(({data}) => {
                this.context.commit(Mutations.SET_ADMIN_SUBSCRIBER_REQUEST_GUEST_MEAL_LIST, data.alternative_guest_meals);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetch subscriber guest meal request delete
    [Actions.DELETE_ADMIN_SUBSCRIBER_REQUEST_GUEST_MEAL](payload) {
        return ApiService.delete("/admin/subscriber/alternative-guest-meal/" + payload)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetch subscriber location
    [Actions.FETCH_ADMIN_SUBSCRIBER_LOCATION_LIST](payload) {
        return ApiService.get("/admin/subscriber/alternative-location/list/" + payload)
            .then(({data}) => {
                this.context.commit(Mutations.SET_ADMIN_SUBSCRIBER_LOCATION_LIST, data.alternative_locations);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetch subscriber location delete
    [Actions.DELETE_ADMIN_SUBSCRIBER_LOCATION](payload) {
        return ApiService.delete("/admin/subscriber/alternative-location/" + payload)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetch subscriber
    [Actions.FETCH_ADMIN_INDIVIDUALLY_SUBSCRIBER](payload) {
        return ApiService.get("/admin/individually/subscriber/" + payload)
            .then(({data}) => {
                this.context.commit(Mutations.SET_ADMIN_INDIVIDUALLY_SUBSCRIBER, data.subscriber);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

}


// common functions
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 2000,
        })
    } else if (type == 'success') {
        useToast().success(data.message, {
            timeout: 2000,
        })
    }
}
