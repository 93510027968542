import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/SubscriptionEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";

import Swal from "sweetalert2/dist/sweetalert2.min.js";
import {useToast} from "vue-toastification";
import {array} from "yup";

export interface Subscription {
    subscription: object
}

export interface Subscriptions {
    subscriptions: Array<object>
}
export interface SubscriptionButtonVisibility {
    subscription_button_visibility: object
}

export interface TodayLunchSubscriptions {
    today_lunch_subscriptions: Array<object>
}
export interface TodayBreakfastSubscriptions {
    today_breakfast_subscriptions: Array<object>
}

export interface TodayDeliverySubscriptions {
    today_delivery_subscriptions: Array<object>
}

export interface SubscriberDailySubscriptions {
    subscriber_daily_subscriptions: Array<object>
}

export interface SubscriberDailySubscription {
    subscriber_daily_subscription: Array<object>
}

export interface TodayBreakfastSubscriptionIds {
    today_breakfast_subscription_ids: Array<object>
}
export interface getTodayLunchSubscriptionIds {
    today_lunch_subscription_ids: Array<object>
}

export interface TodayLunchGuestMealSubscriptions {
    today_lunch_guest_meal_subscriptions: Array<object>
}

export interface TodayLunchGuestMealSubscriptionIds {
    today_lunch_guest_meal_subscription_ids: Array<object>
}

export interface TodayBreakfastGuestMealSubscriptions {
    today_breakfast_guest_meal_subscriptions: Array<object>
}

export interface TodayBreakfastGuestMealSubscriptionIds {
    today_breakfast_guest_meal_subscription_ids: Array<object>
}

export interface SubscriptionInfo {
    errors: unknown;
    success: unknown;
    subscriptions: Subscriptions;
    subscription_button_visibility: SubscriptionButtonVisibility;
    breakfast_subscription: Subscription;
    lunch_subscription: Subscription;
    today_lunch_subscriptions: TodayLunchSubscriptions;
    today_delivery_subscriptions: TodayDeliverySubscriptions;
    subscriber_daily_subscriptions: SubscriberDailySubscriptions;
    today_breakfast_subscription_ids: TodayBreakfastSubscriptionIds;
    today_lunch_subscription_ids: getTodayLunchSubscriptionIds;
    subscriber_daily_subscription: SubscriberDailySubscription;
    today_breakfast_guest_meal_subscriptions: TodayBreakfastGuestMealSubscriptions;
    today_breakfast_guest_meal_subscription_ids: TodayBreakfastGuestMealSubscriptionIds;
    today_lunch_guest_meal_subscriptions: TodayLunchGuestMealSubscriptions;
    today_lunch_guest_meal_subscription_ids: TodayLunchGuestMealSubscriptionIds;
    today_breakfast_subscriptions: TodayBreakfastSubscriptions;
}

@Module
export default class SubscriptionModule extends VuexModule implements SubscriptionInfo {
    errors = {};
    success = {};
    subscriptions = {} as Subscriptions;
    subscription_button_visibility = {} as SubscriptionButtonVisibility;
    breakfast_subscription = {} as Subscription;
    lunch_subscription = {} as Subscription;
    today_lunch_subscriptions = {} as TodayLunchSubscriptions;
    today_breakfast_subscriptions = {} as TodayBreakfastSubscriptions;
    today_delivery_subscriptions = {} as TodayDeliverySubscriptions;
    subscriber_daily_subscriptions = {} as SubscriberDailySubscriptions;
    subscriber_daily_subscription = {} as SubscriberDailySubscription;
    today_lunch_subscription_ids = {} as getTodayLunchSubscriptionIds;
    today_breakfast_subscription_ids = {} as TodayBreakfastSubscriptionIds;
    today_breakfast_guest_meal_subscriptions = {} as TodayBreakfastGuestMealSubscriptions;
    today_breakfast_guest_meal_subscription_ids = {} as TodayBreakfastGuestMealSubscriptionIds;
    today_lunch_guest_meal_subscriptions = {} as TodayLunchGuestMealSubscriptions;
    today_lunch_guest_meal_subscription_ids = {} as TodayLunchGuestMealSubscriptionIds;

    /**
     * Get authentification success
     * @returns array
     */
    get getSubscriptionSuccess() {
        return this.success;
    }

    /**
     * Get authentification errors
     * @returns array
     */
    get getSubscriptionErrors() {
        return this.errors;
    }

    /**
     * shows user type list
     * @returns object
     */
    get getSubscriptions(): object {
        return this.subscriptions;
    }

    get getSubscription_button_visibility(): object {
        return this.subscription_button_visibility;
    }

    /**
     * shows user type list
     * @returns object
     */
    get getBreakfastSubscription(): object {
        return this.breakfast_subscription;
    }
    /**
     * shows user type list
     * @returns object
     */
    get getLunchSubscription(): object {
        return this.lunch_subscription;
    }

    /**
     * shows user type list
     * @returns object
     */
    get getTodayBreakfastSubscriptions(): object {
        return this.today_breakfast_subscriptions;
    }

    /**
     * shows user type list
     * @returns object
     */
    get getTodayLunchSubscriptions(): object {
        return this.today_lunch_subscriptions;
    }

    /**
     * shows user type list
     * @returns object
     */
    get getTodayBreakfastSubscriptionIds(): object {
        return this.today_breakfast_subscription_ids;
    }
    /**
     * shows user type list
     * @returns object
     */
    get getTodayLunchSubscriptionIds(): object {
        return this.today_lunch_subscription_ids;
    }

    /**
     * shows user type list
     * @returns object
     */
    get getTodayBreakfastGuestMealSubscriptions(): object {
        return this.today_breakfast_guest_meal_subscriptions;
    }
    /**
     * shows user type list
     * @returns object
     */
    get getTodayLunchGuestMealSubscriptions(): object {
        return this.today_lunch_guest_meal_subscriptions;
    }

    /**
     * shows user type list
     * @returns object
     */
    get getTodayBreakfastGuestMealSubscriptionIds(): object {
        return this.today_breakfast_guest_meal_subscription_ids;
    }

    /**
     * shows user type list
     * @returns object
     */
    get getTodayLunchGuestMealSubscriptionIds(): object {
        return this.today_lunch_guest_meal_subscription_ids;
    }

    /**
     * shows user type list
     * @returns object
     */
    get getTodayDeliverySubscriptions(): object {
        return this.today_delivery_subscriptions;
    }

    /**
     * shows user type list
     * @returns object
     */
    get getSubscriberDailySubscriptions(): object {
        return this.subscriber_daily_subscriptions;
    }

    /**
     * shows user type list
     * @returns object
     */
    get getSubscriberDailySubscription() {
        return this.subscriber_daily_subscription;
    }

    @Mutation
    [Mutations.SET_SUCCESS](success) {
        this.success = {...success};
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = {...error};
    }

    @Mutation // fetched subscription list data are saved in this function
    [Mutations.SET_SUBSCRIPTION_LIST](payload) {
        this.subscriptions = payload
    }
    @Mutation // fetched subscription button visibility  data are saved in this function
    [Mutations.SET_SUBSCRIPTION_BUTTON_VISIBILITY](payload) {
        this.subscription_button_visibility = payload
    }

    @Mutation // fetched subscription
    [Mutations.SET_LATEST_BREAKFAST_SUBSCRIPTION](payload) {
        this.breakfast_subscription = payload
    }
    @Mutation // fetched subscription
    [Mutations.SET_LATEST_LUNCH_SUBSCRIPTION](payload) {
        this.lunch_subscription = payload
    }

    @Mutation // fetched today lunch subscription list data are saved in this function
    [Mutations.SET_TODAY_LUNCH_SUBSCRIPTION_LIST](payload) {
        this.today_lunch_subscriptions = payload
    }

    @Mutation // fetched subscription list data are saved in this function
    [Mutations.SET_TODAY_LUNCH_SUBSCRIPTION_IDS_LIST](payload) {
        this.today_lunch_subscription_ids = payload
    }

    @Mutation // fetched today breakfast subscription list data are saved in this function
    [Mutations.SET_TODAY_BREAKFAST_SUBSCRIPTION_LIST](payload) {
        this.today_breakfast_subscriptions = payload
    }

    @Mutation // fetched today breakfast subscription list data are saved in this function
    [Mutations.SET_TODAY_BREAKFAST_SUBSCRIPTION_IDS_LIST](payload) {
        this.today_breakfast_subscription_ids = payload
    }

    @Mutation // fetched today lunch subscription list data are saved in this function
    [Mutations.SET_ADMIN_TODAY_BREAKFAST_SUBSCRIPTION_LIST](payload) {
        this.today_breakfast_subscriptions = payload
    }

    @Mutation // fetched today lunch subscription list data are saved in this function
    [Mutations.SET_ADMIN_TODAY_BREAKFAST_SUBSCRIPTION_IDS_LIST](payload) {
        this.today_breakfast_subscription_ids = payload
    }

    @Mutation // fetched today lunch subscription list data are saved in this function
    [Mutations.SET_ADMIN_TODAY_LUNCH_SUBSCRIPTION_LIST](payload) {
        this.today_lunch_subscriptions = payload
    }

    @Mutation // fetched today lunch subscription list data are saved in this function
    [Mutations.SET_ADMIN_TODAY_LUNCH_SUBSCRIPTION_IDS_LIST](payload) {
        this.today_lunch_subscription_ids = payload
    }

    @Mutation // fetched subscription breakfast guest meal list data are saved in this function
    [Mutations.SET_TODAY_BREAKFAST_GUEST_MEAL_SUBSCRIPTION_LIST](payload) {
        this.today_breakfast_guest_meal_subscriptions = payload
    }
    @Mutation // fetched subscription lunch guest meal list data are saved in this function
    [Mutations.SET_TODAY_LUNCH_GUEST_MEAL_SUBSCRIPTION_LIST](payload) {
        this.today_lunch_guest_meal_subscriptions = payload
    }

    @Mutation // fetched subscription lunch guest meal list data are saved in this function
    [Mutations.SET_ADMIN_TODAY_GUEST_MEAL_SUBSCRIPTION_LIST](payload) {
        this.today_lunch_guest_meal_subscriptions = payload
    }

    @Mutation // fetched subscription breakfast guest meal ids list data are saved in this function
    [Mutations.SET_TODAY_BREAKFAST_GUEST_MEAL_SUBSCRIPTION_IDS_LIST](payload) {
        this.today_breakfast_guest_meal_subscription_ids = payload
    }

    @Mutation // fetched subscription guest meal ids list data are saved in this function
    [Mutations.SET_TODAY_LUNCH_GUEST_MEAL_SUBSCRIPTION_IDS_LIST](payload) {
        this.today_lunch_guest_meal_subscription_ids = payload
    }

    @Mutation // fetched subscription guest meal ids list data are saved in this function
    [Mutations.SET_ADMIN_TODAY_GUEST_MEAL_SUBSCRIPTION_IDS_LIST](payload) {
        this.today_lunch_guest_meal_subscription_ids = payload
    }

    @Mutation // fetched subscription list data are saved in this function
    [Mutations.SET_TODAY_DELIVERY_SUBSCRIPTION_LIST](payload) {
        this.today_delivery_subscriptions = payload
    }

    @Mutation
    [Mutations.SET_SUBSCRIBER_DAILY_SUBSCRIPTION_LIST](payload) {
        this.subscriber_daily_subscriptions = payload
    }

    @Mutation
    [Mutations.SET_SUBSCRIBER_DAILY_SUBSCRIPTION](payload) {
        this.subscriber_daily_subscription = payload
    }

    @Action // fetch subscriptions
    [Actions.FETCH_SUBSCRIPTION_LIST]() {
        return ApiService.get("/subscriptions")
            .then(({data}) => {
                this.context.commit(Mutations.SET_SUBSCRIPTION_LIST, data.subscriptions);
                this.context.commit(Mutations.SET_SUBSCRIPTION_BUTTON_VISIBILITY, data.subscription_button_visibility);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetch subscription
    [Actions.FETCH_LATEST_SUBSCRIPTION]() {
        return ApiService.get("/latest-subscription")
            .then(({data}) => {
                this.context.commit(Mutations.SET_LATEST_BREAKFAST_SUBSCRIPTION, data.breakfast_subscription);
                this.context.commit(Mutations.SET_LATEST_LUNCH_SUBSCRIPTION, data.lunch_subscription);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetch today subscriptions for admin
    [Actions.FETCH_ADMIN_TODAY_LUNCH_SUBSCRIPTION_LIST](payload) {
        return ApiService.post("/admin/today-lunch-subscriptions", payload)
            .then(({data}) => {
                this.context.commit(Mutations.SET_ADMIN_TODAY_LUNCH_SUBSCRIPTION_LIST, data.today_lunch_subscriptions);
                this.context.commit(Mutations.SET_ADMIN_TODAY_LUNCH_SUBSCRIPTION_IDS_LIST, data.today_lunch_subscription_ids);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }
    @Action // fetch today subscriptions for admin
    [Actions.FETCH_ADMIN_TODAY_BREAKFAST_SUBSCRIPTION_LIST](payload) {
        return ApiService.post("/admin/today-breakfast-subscriptions", payload)
            .then(({data}) => {
                this.context.commit(Mutations.SET_ADMIN_TODAY_BREAKFAST_SUBSCRIPTION_LIST, data.today_breakfast_subscriptions);
                this.context.commit(Mutations.SET_ADMIN_TODAY_BREAKFAST_SUBSCRIPTION_IDS_LIST, data.today_breakfast_subscription_ids);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetch today breakfast subscriptions
    [Actions.FETCH_TODAY_BREAKFAST_SUBSCRIPTION_LIST]() {
        return ApiService.get("/today-breakfast-subscriptions")
            .then(({data}) => {
                this.context.commit(Mutations.SET_TODAY_BREAKFAST_SUBSCRIPTION_LIST, data.today_breakfast_subscriptions);
                this.context.commit(Mutations.SET_TODAY_BREAKFAST_SUBSCRIPTION_IDS_LIST, data.today_breakfast_subscription_ids);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetch today lunch subscriptions
    [Actions.FETCH_TODAY_LUNCH_SUBSCRIPTION_LIST]() {
        return ApiService.get("/today-lunch-subscriptions")
            .then(({data}) => {
                this.context.commit(Mutations.SET_TODAY_LUNCH_SUBSCRIPTION_LIST, data.today_lunch_subscriptions);
                this.context.commit(Mutations.SET_TODAY_LUNCH_SUBSCRIPTION_IDS_LIST, data.today_lunch_subscription_ids);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetch today subscription breakfast guest meal
    [Actions.FETCH_TODAY_BREAKFAST_GUEST_MEAL_SUBSCRIPTION_LIST]() {
        return ApiService.get("/subscription/subscriber/today-breakfast-guest-meal")
            .then(({data}) => {
                this.context.commit(Mutations.SET_TODAY_BREAKFAST_GUEST_MEAL_SUBSCRIPTION_LIST, data.today_breakfast_guest_meal_subscriptions);
                this.context.commit(Mutations.SET_TODAY_BREAKFAST_GUEST_MEAL_SUBSCRIPTION_IDS_LIST, data.today_breakfast_guest_meal_subscription_ids);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetch today subscription lunch guest meal
    [Actions.FETCH_TODAY_LUNCH_GUEST_MEAL_SUBSCRIPTION_LIST]() {
        return ApiService.get("/subscription/subscriber/today-lunch-guest-meal")
            .then(({data}) => {
                this.context.commit(Mutations.SET_TODAY_LUNCH_GUEST_MEAL_SUBSCRIPTION_LIST, data.today_lunch_guest_meal_subscriptions);
                this.context.commit(Mutations.SET_TODAY_LUNCH_GUEST_MEAL_SUBSCRIPTION_IDS_LIST, data.today_lunch_guest_meal_subscription_ids);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetch today subscription guest meal
    [Actions.FETCH_ADMIN_TODAY_BREAKFAST_GUEST_MEAL_SUBSCRIPTION_LIST](payload) {
        return ApiService.post("/subscription/subscriber/admin/today-breakfast-guest-meal", payload)
            .then(({data}) => {
                this.context.commit(Mutations.SET_ADMIN_TODAY_GUEST_MEAL_SUBSCRIPTION_LIST, data.today_breakfast_guest_meal_subscriptions);
                this.context.commit(Mutations.SET_ADMIN_TODAY_GUEST_MEAL_SUBSCRIPTION_IDS_LIST, data.today_breakfast_guest_meal_subscription_ids);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetch today subscription guest meal
    [Actions.FETCH_ADMIN_TODAY_LUNCH_GUEST_MEAL_SUBSCRIPTION_LIST](payload) {
        return ApiService.post("/subscription/subscriber/admin/today-lunch-guest-meal", payload)
            .then(({data}) => {
                this.context.commit(Mutations.SET_ADMIN_TODAY_GUEST_MEAL_SUBSCRIPTION_LIST, data.today_lunch_guest_meal_subscriptions);
                this.context.commit(Mutations.SET_ADMIN_TODAY_GUEST_MEAL_SUBSCRIPTION_IDS_LIST, data.today_lunch_guest_meal_subscription_ids);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetch today delivery subscriptions
    [Actions.FETCH_TODAY_DELIVERY_SUBSCRIPTION_LIST]() {
        return ApiService.get("/today-delivery-subscriptions")
            .then(({data}) => {
                this.context.commit(Mutations.SET_TODAY_DELIVERY_SUBSCRIPTION_LIST, data.today_delivery_subscriptions);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetch subscriber daily subscriptions
    [Actions.FETCH_SUBSCRIBER_DAILY_SUBSCRIPTION_LIST](payload) {
        return ApiService.get("/subscriber/daily-subscription/list/" + payload)
            .then(({data}) => {
                this.context.commit(Mutations.SET_SUBSCRIBER_DAILY_SUBSCRIPTION_LIST, data.subscriber_daily_subscriptions);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // fetch subscriber daily subscriptions
    [Actions.FETCH_SUBSCRIBER_DAILY_SUBSCRIPTION]() {
        return ApiService.get("/subscriber/daily-subscription")
            .then(({data}) => {
                this.context.commit(Mutations.SET_SUBSCRIBER_DAILY_SUBSCRIPTION, data.subscriber_daily_subscription);
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // store subscription
    [Actions.STORE_TODAY_BREAKFAST_SUBSCRIPTION](payload) {
        return ApiService.post("/subscription/subscriber/breakfast-is-delivered", payload)
            .then(({data}) => {
                swal(data, '#FFF')
            })
            .catch(({response}) => {
                swal(response.data, '#ff5252')
            });
    }
    @Action // store subscription
    [Actions.STORE_TODAY_LUNCH_SUBSCRIPTION](payload) {
        return ApiService.post("/subscription/subscriber/lunch-is-delivered", payload)
            .then(({data}) => {
                swal(data, '#FFF')
            })
            .catch(({response}) => {
                swal(response.data, '#ff5252')
            });
    }

    @Action // store all subscriber subscription
    [Actions.STORE_TODAY_ALL_BREAKFAST_SUBSCRIBER_SUBSCRIPTION](payload) {
        return ApiService.post("/subscription/subscriber/all/breakfast-is-delivered", payload)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // store all subscriber subscription
    [Actions.STORE_TODAY_ALL_LUNCH_SUBSCRIBER_SUBSCRIPTION](payload) {
        return ApiService.post("/subscription/subscriber/all/lunch-is-delivered", payload)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // store subscription guest meal
    [Actions.STORE_TODAY_BREAKFAST_GUEST_MEAL_SUBSCRIPTION](payload) {
        return ApiService.post("/subscription/subscriber/breakfast-guest-meal/isDelivered", payload)
            .then(({data}) => {
                swal(data, '#FFF')
            })
            .catch(({response}) => {
                swal(response.data, '#ff5252')
            });
    }

    @Action // store subscription guest meal
    [Actions.STORE_TODAY_LUNCH_GUEST_MEAL_SUBSCRIPTION](payload) {
        return ApiService.post("/subscription/subscriber/lunch-guest-meal/isDelivered", payload)
            .then(({data}) => {
                swal(data, '#FFF')
            })
            .catch(({response}) => {
                swal(response.data, '#ff5252')
            });
    }

    @Action // store subscription all guest meal
    [Actions.STORE_TODAY_ALL_BREAKFAST_GUEST_MEAL_SUBSCRIPTION](payload) {
        return ApiService.post("/subscription/subscriber/all-breakfast-guest-meal/isDelivered", payload)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // store subscription all guest meal
    [Actions.STORE_TODAY_ALL_LUNCH_GUEST_MEAL_SUBSCRIPTION](payload) {
        return ApiService.post("/subscription/subscriber/all-lunch-guest-meal/isDelivered", payload)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }


    @Action // start subscription
    [Actions.START_SUBSCRIPTION](payload) {
        return ApiService.post("/subscription/start-subscription", payload)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // cancel subscription
    [Actions.CANCEL_SUBSCRIPTION](payload) {
        return ApiService.post("/subscription/cancel-subscription", payload)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // admin today lunch subscription Excel download
    [Actions.ADMIN_TODAY_LUNCH_SUBSCRIPTION_EXCEL_DOWNLOAD](payload) {
        return ApiService.pdfDownloadPost("/admin/subscription/today-lunch/excel/download", payload)
            .then(({data}) => {
                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'today_subscription.xlsx')
                document.body.appendChild(link)
                link.click()
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // admin today breakfast subscription Excel download
    [Actions.KITCHEN_COUNTER_TODAY_LUNCH_SUBSCRIPTION_EXCEL_DOWNLOAD](payload) {
        return ApiService.pdfDownloadPost("/kitchen-counter/subscription/today-lunch/excel/download", payload)
            .then(({data}) => {
                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'today_subscription.xlsx')
                document.body.appendChild(link)
                link.click()
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // admin today subscription Excel download
    [Actions.ADMIN_TODAY_BREAKFAST_SUBSCRIPTION_EXCEL_DOWNLOAD](payload) {
        return ApiService.pdfDownloadPost("/admin/subscription/today-breakfast/excel/download", payload)
            .then(({data}) => {
                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'today_subscription.xlsx')
                document.body.appendChild(link)
                link.click()
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action // admin today subscription Excel download
    [Actions.KITCHEN_COUNTER_TODAY_BREAKFAST_SUBSCRIPTION_EXCEL_DOWNLOAD](payload) {
        return ApiService.pdfDownloadPost("/kitchen-counter/subscription/today-breakfast/excel/download", payload)
            .then(({data}) => {
                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'today_subscription.xlsx')
                document.body.appendChild(link)
                link.click()
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

}

// common functions
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 2000,
        })
    } else if (type == 'success') {
        useToast().success(data.message, {
            timeout: 2000,
        })
    }
}

const swal = (data, color) => {
    let timerInterval

    if (color === '#FFF') {
        Swal.fire({
            title: data.user ? data.user.name : 'No User Found',
            html: data.message,
            imageUrl: data.user ? data.user.profile_picture_url : '/media/avatars/dead_emoji.png',
            imageWidth: 300,
            imageHeight: 200,
            imageAlt: 'No Image Found',
            timer: 2000,
            timerProgressBar: true,
            showConfirmButton: false,
            background: color,
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            },
        })
    } else {
        Swal.fire({
            title: `<span style="color:#fff">${data.user ? data.user.name : 'No User Found'}</span>`,
            html: `<h6 style="color:#fff">${data.message}</h6>`,
            imageUrl: data.user ? data.user.profile_picture_url : '/media/avatars/dead_emoji.png',
            imageWidth: data.user ? 300 : 200,
            imageHeight: 200,
            imageAlt: 'No Image Found',
            timer: 2000,
            timerProgressBar: true,
            showConfirmButton: false,
            background: color,
            width: 350,
            padding: '1em',
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            },
        })
    }
}
