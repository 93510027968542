import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/SettingEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";

import {useToast} from "vue-toastification";

export interface mealRateData {
    meal_rates: object
}

@Module
export default class MealRateModule extends VuexModule implements mealRateData {

    meal_rates = {}

    /**
     * shows feast list
     * @returns object
     */
    get getMealRateList(): object {
        return this.meal_rates;
    }

    @Mutation // fetched meal rate list data are saved in this function
    [Mutations.SET_MEAL_RATE_LIST](payload) {
        this.meal_rates = payload
    }

    @Action // fetchMealrateList
    [Actions.FETCH_MEAL_RATE_LIST]() {
        return ApiService.get("/meal-rates").then(({data}) => {
            this.context.commit(Mutations.SET_MEAL_RATE_LIST, data.meal_rates);
        }).catch(({response}) => {
            toast('error', response.data)
        });
    }

    @Action // storeMealRate
    [Actions.STORE_MEAL_RATE](payload) {
        return ApiService.post("/meal-rates", payload).then(({data}) => {
            toast('success', data)
        }).catch(({response}) => {
            toast('error', response.data)
        });
    }

    @Action // updateMealRate
    [Actions.UPDATE_MEAL_RATE](payload) {
        return ApiService.put("/meal-rates/" + payload.id, payload)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }

    @Action //deleteDFeast
    [Actions.DELETE_MEAL_RATE](payload) {
        return ApiService.delete("/meal-rates/" + payload.id)
            .then(({data}) => {
                toast('success', data)
            })
            .catch(({response}) => {
                toast('error', response.data)
            });
    }
}


// common function
const toast = (type, data) => {
    if (type == 'error') {
        useToast().error(data.message, {
            timeout: 5000,
        })
    } else {
        useToast().success(data.message, {
            timeout: 5000,
        })
    }
}
