import { createApp } from "vue";

import App from "./App.vue";

// ant-design
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';


/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */

import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";
import Toast, { PluginOptions, POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";

import { abilitiesPlugin, Can } from "@casl/vue";
import ability from "@/config/ability";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import "@/core/plugins/prismjs";

// import Select2Component
import Select2 from 'vue3-select2-component';

// Select2
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import '../node_modules/nprogress/nprogress.css'

// Pusher
import Echo from "laravel-echo"
import JwtService from "@/core/services/JwtService";
window.Pusher = require('pusher-js');

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_WS_KEY,
    cluster: process.env.VUE_APP_WS_CLUSTER,
    wsPath: process.env.VUE_APP_WS_PATH,
    wsHost: process.env.VUE_APP_WS_HOST,
    wsPort: process.env.VUE_APP_WS_PORT,
    wssPort: process.env.VUE_APP_WSS_PORT,
    forceTLS: false,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
    authEndpoint: process.env.VUE_APP_API_URL + '/broadcasting/auth',
    auth: {
        headers: {
            Authorization: 'Bearer ' + JwtService.getToken(),
            Accept: 'application/json',
        },
    },
})

// Toast notification plugin option
const options: PluginOptions = {
    position: POSITION.TOP_RIGHT
};

const app = createApp(App);

app.use(Antd);
app.use(store);
app.use(router);
app.use(ElementPlus);

app.use(abilitiesPlugin, ability, {
    useGlobalProperties: true
});
app.component('Can', Can)
app.component('Datepicker', Datepicker);
app.component('Select2', Select2)
app.component("v-select", vSelect);


ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);
app.use(Toast, options);

app.mount("#app");
